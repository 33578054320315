import { EventHint, Event } from '@sentry/react';
import { isCancel } from 'axios';

export function handleSentryBeforeSend(event: Event, hint: EventHint) {
  if (isCancel(hint?.originalException)) {
    console.debug('Cancelled request ignored by Sentry', hint.originalException);
    return null;
  }
  if (JSON.stringify(hint?.originalException)?.includes('ably')) {
    console.debug('any ably error ignored by Sentry', hint.originalException);
    return null;
  }
  return event;
}
