/* eslint-disable no-console */

export default function Version() {
  console.log('%c %s', 'color: #676767;', '------------------');
  console.log(
    '%c %s',
    'color: #676767;',
    `[Dipp Platform] Version ::: ${process.env.VITE_APP_VERSION || 'run with local service'}`,
  );
  console.log('%c %s', 'color: #676767;', '------------------');
}
